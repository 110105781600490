import React from "react";
import Home from "../component/Home/Home";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import "../styles/global.css";
import citySpecificImage from "../images/cities/rolando.png";

const App = () => {
  const pageData = {
    residentialRealtors: {
      title: "Realtors in Rolando, CA",
      subtitle: "With Your Best Interests At Heart",
      description:
        "This is about more than a deal—it's about your family's happiness and building a great future. So whether you're interested in Rolando village homes for sale, are looking to sell your house, or would like to explore property investment options, our experienced residential real estate professionals will connect you with the best opportunities. And we'll do it with heart because we're a family too.",
    },
    findNextHome: {
      card1Title: "Rolando Homes for Sale",
      card2Title: "What Is Your Home Worth?",
    },
    listings: {
      title: "Find Your Place in San Diego",
      description: (
        <p>
          From Allied Gardens to University Heights and every community in between, discover the
          latest San Diego properties here. Our listings are pulled directly from the MLS and
          updated in real-time, giving you immediate access to homes the minute they hit the market.
          Your home search begins here!
        </p>
      ),
    },
    meetFamily: {
      title: "Meet Our Family",
      subtitle:
        "We’re dedicated realtors who help families like yours throughout the homeownership journey—from buying real estate in Rolando to selling to investing. We’re advisors who are in your corner from day one.",
    },
    citySpecific: {
      titlePartA: "Serving Picturesque",
      titlePartB: "Rolando",
      p1: "Tucked away in Eastern San Diego, you’ll find beautiful Rolando, a picturesque neighborhood first built in the 40s and 50s. Because of that, residents today enjoy lovely bungalow-style houses and nostalgic architecture, all set against the backdrop of breathtaking rolling hills.",
      p2: "Home to the Rolando Little League ball fields, Ray & Joan KROC Center, and Clay Park, this family-friendly area is the perfect place to call home. If you’re interested in real estate in Rolando, CA, get in touch with our Selby professionals today. We can help you buy, sell, or invest in property in the Rolando area!",
      image: citySpecificImage,
    },
    cta: {
      description:
        "Connect with a top-rated team of Rolando realtors who listen, are readily available, and will work tirelessly for you.",
    },
  };
  return (
    <LayoutWrapper
      title="Rolando Homes for Sale | Real Estate | Selby Realtors"
      desc="Searching for homes for sale in Rolando? The Selby Team has the newest listings for this beautiful San Diego-area community."
      headerLogo="../../images/Selby_logo.svg"
    >
      <Home pageData={pageData} />
    </LayoutWrapper>
  );
};

export default App;
